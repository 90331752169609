



import WalletSection from '../sections/wallet/default';




import "./style.css";


const WalletPage = ()=>{
  
return (
  <div id="walletPage" class="page ">
  
  

  
  <WalletSection />
  
  
  

  
</div>
)
}

export default WalletPage;