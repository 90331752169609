



import MainBanner from '../sections/banner/template_01';







import SpeakerAnnouncement from '../sections/speaker_announcement/template_01';







import Games from '../sections/games/template_01';






import SECTION_SECTION_GAMETYPE from
'../dynamic_section/dynamic_1/template_02';





import SECTION_SECTION_POPULAR_GAMES_WE_LOVE from
'../dynamic_section/dynamic_1/template_02';






import RankingList from '../sections/ranking/template_02';






import SECTION_SECTION_MAINPAGE_PROMOTION from
'../dynamic_section/dynamic_1/template_01';





import SECTION_SECTION_SERVICE from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";

import FloatingIcon from '../components/FloatingIcon';
import { useEffect, useState } from "react";
import { useMiddletier } from "../common/middletier";
import { useTranslation } from 'react-i18next';


const Main = ()=>{
  
  const [floatingIcon, setFloatingIcon] = useState([])
  const { queries, mutation } = useMiddletier()
  const { t, i18n } = useTranslation();
  useEffect(() => {
    queries([
      {
        index: 'getFloatingIcons',
        method: 'getFloatingIcons',
        params: [
          { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
          { code: 'website', graphqlType: 'String', required: false, value: 'MEMBER' },
        ],
        attributes: []
      }
    ])
      .then(({ data }) => {
        setFloatingIcon(data['getFloatingIcons'])
      })
      .catch((error) => {
        console.error(error)
      })
  }, [queries, i18n.resolvedLanguage])
  
return (
  <div id="main" class="page ">
  
  

  
  <MainBanner navigation={ false } speed={
    4 } pagination={ true } />
  
  
  
  

  
  <SpeakerAnnouncement desktopToggle={ true } mobileToggle={
    true } iconToggle={ true } titleToggle={
    false } />
  
  
  
  

  
  <Games desktopToggle={ false } mobileToggle={
    true } imageToggle={ true } titleToggle={
    false } gameProviderNameToggle={ true } rowMobile={
    4 } rowDesktop={ 4 } position={`vertical`} imageSize={
    `1x1` } folderImage={`dropdown`} menuPosition={`vertical`} sliderToggle={ false } sliderRow={ 1 } pagination={ true }
    autoplay={ true } navigation={ true }/>
  
  
  
  

  
  < SECTION_SECTION_GAMETYPE desktopToggle={ true }
    mobileToggle={ false } label={ `Section_GAME_TYPE` } id={ `Section_gameType` }
    imageSize={`1x2`} contentPosition={`none`}
    actionPosition={``} rowDesktop={ 3 }
    rowMobile={ 2 } numberOfRow={ 1 }
    dataType={`gameTypes`} noPadding={ false } filter={``}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={``} pagination={ false }
    autoplay={ true } navigation={ false }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  
  

  
  < SECTION_SECTION_POPULAR_GAMES_WE_LOVE desktopToggle={ true }
    mobileToggle={ true } label={ `Section_Popular_Games_We_Love` } id={ `Section_Popular_Games_We_Love` }
    imageSize={`1x1`} contentPosition={`bottom`}
    actionPosition={`hover`} rowDesktop={ 6 }
    rowMobile={ 3 } numberOfRow={ 1 }
    dataType={`game`} noPadding={ false } filter={`SL`}
    titleToggle={ true } titleIconToggle={ true }
    cardPosition={``} pagination={ false }
    autoplay={ true } navigation={ false }
    dynamicQuery={`HOT` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  
  

  
  <RankingList desktopToggle={ true } mobileToggle={
    true } desktopBannerToggle={ false } mobileBannerToggle={
    false } badgeToggle={ true } userAvatarToggle={
    false } row={ 5 } titleIconToggle={ true }/>
  
  
  
  

  
  < SECTION_SECTION_MAINPAGE_PROMOTION desktopToggle={ true }
    mobileToggle={ true } label={ `Section_mainPage_promotion` } id={ `Section_mainPage_promotion` }
    imageSize={`1x3`} contentPosition={`none`}
    actionPosition={`none`} rowDesktop={ 1 }
    rowMobile={ 1 } numberOfRow={ 1 }
    dataType={`promotion`} noPadding={ false } filter={``}
    titleToggle={ true } titleIconToggle={ true }
    cardPosition={``} pagination={ true }
    autoplay={ true } navigation={ true }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  
  

  
  < SECTION_SECTION_SERVICE desktopToggle={ true }
    mobileToggle={ false } label={ `Section_Service` } id={ `Section_Service` } supportLanguage={
    true } titleToggle={ true } titleIconToggle={
    true } linkTo={ `` } addContainer={ true } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  

  
  {floatingIcon.length > 0 && (floatingIcon?.map(function (item, index) {
    return (
      <FloatingIcon position={item?._floating_icon?.position} enableClose={item?._floating_icon?.enable_close} enableMove={item?._floating_icon?.enable_move} desktopImageUrl={item?.desktop_image_url} mobileImageUrl={item?.mobile_image_url} url={item?._floating_icon?.url} />)
  }))}

</div>
)
}

export default Main;