


import SECTION_CASINOGAMEBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_CASINOGAMECONTAINERSECTION from
'../dynamic_section/dynamic_1/template_01';



import "./style.css";


const CasinoPageDesktop = ()=>{
  
return (
  <div id="casinoPageDesktop" class="page ">
  
  

  
  < SECTION_CASINOGAMEBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `casino_game_banner` } id={ `casino_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  < SECTION_CASINOGAMECONTAINERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `Section_casino_game` } id={ `casinoGameContainer` }
    imageSize={`1x2`} contentPosition={`bottom`}
    actionPosition={``} rowDesktop={ 3 }
    rowMobile={ 3 } numberOfRow={ -1 }
    dataType={`game provider`} noPadding={ false } filter={`LC`}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={`start`} pagination={ true }
    autoplay={ true } navigation={ true }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  

  
</div>
)
}

export default CasinoPageDesktop;