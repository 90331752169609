



import LoginMobile from '../sections/login/template_01';




import "./style.css";


const LoginPageMobile = ()=>{
  
return (
  <div id="loginPageMobile" class="page ">
  
  

  
  <LoginMobile mobileTitleToggle={ true } />
  
  
  

  
</div>
)
}

export default LoginPageMobile;