


import SECTION_VIPBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_VIPCONTENTSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_VIPCONTENTMSECTION from
'../dynamic_section/dynamic_2/template_01';






import VIPSection from '../sections/vip/default';






import SECTION_FOOTER_BANNER from
'../dynamic_section/dynamic_2/template_01';



import "./style.css";


const VipPage = ()=>{
  
return (
  <div id="vipPage" class="page ">
  
  

  
  < SECTION_VIPBANNERSECTION desktopToggle={ true }
    mobileToggle={ true } label={ `vip banner` } id={ `vip_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  < SECTION_VIPCONTENTSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `vip content` } id={ `vip_content` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  < SECTION_VIPCONTENTMSECTION desktopToggle={ false }
    mobileToggle={ true } label={ `vip content_m` } id={ `vip_content_m` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  <VIPSection desktopToggle={ false } mobileToggle={
    false } bgSection={ false } mobileTitleToggle={ true } mobileBannerToggle={ false } desktopBannerToggle={
    false } mode={``} vipContentToggle={ false } aboutUsToggle={ true }
    faqToggle={ true } tncToggle={ true } benefitToggle={
    true } reviewToggle={ true }  vipOrder={  []  } linkedSlider={  []  }/>
  
  
  
  

  
  < SECTION_FOOTER_BANNER desktopToggle={ true }
    mobileToggle={ false } label={ `Dynamic Section2` } id={ `footer_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  

  
</div>
)
}

export default VipPage;