


import SECTION_1676511135550 from
'../dynamic_section/dynamic_2/template_01';






import CouponSection from '../sections/coupon/template_01';




import "./style.css";


const CouponPage = ()=>{
  
return (
  <div id="couponPage" class="page ">
  
  

  
  < SECTION_1676511135550 desktopToggle={ true }
    mobileToggle={ false } label={ `coupon_title` } id={ `coupon_title` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  <CouponSection desktopTitleToggle={ false }
    mobileTitleToggle={ true } />
  
  
  

  
</div>
)
}

export default CouponPage;